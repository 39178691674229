/* Generated with http://k88hudson.github.io/syntax-highlighting-theme-generator/www */
/* http://k88hudson.github.io/react-markdocs */
/**
 * @author k88hudson
 *
 * Based on prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */
/*********************************************************
* General
*/
pre[class*="language-"],
code[class*="language-"] {
    color: #5c6e74;
    font-size: 13px;
    text-shadow: none;
    font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
    direction: ltr;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    line-height: 1.5;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
}
pre[class*="language-"]::selection,
code[class*="language-"]::selection,
pre[class*="language-"]::mozselection,
code[class*="language-"]::mozselection {
    text-shadow: none;
    background: #b3d4fc;
}
@media print {
    pre[class*="language-"],
    code[class*="language-"] {
        text-shadow: none;
    }
}
pre[class*="language-"] {
    padding: 1em;
    margin: 0.5em 0;
    overflow: auto;
    background: transparent;
}
:not(pre) > code[class*="language-"] {
    padding: 0.1em 0.3em;
    border-radius: 0.3em;
    color: #db4c69;
    background: #f9f2f4;
}
/*********************************************************
* Tokens
*/
.namespace {
    opacity: 0.7;
}
.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
    color: #93a1a1;
}
.token.punctuation {
    color: #999999;
}
.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
    color: var(--activeColor);
}
.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
    color: #0077aa;
}
.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
    color: #a67f59;
}
.token.atrule,
.token.attr-value,
.token.keyword {
    color: #990055;
}
.token.function {
    color: #669900;
}
.token.regex,
.token.important,
.token.variable {
    color: #ee9900;
}
.token.important,
.token.bold {
    font-weight: bold;
}
.token.italic {
    font-style: italic;
}
.token.entity {
    cursor: help;
}
/*********************************************************
* Line highlighting
*/
pre[data-line] {
    position: relative;
}
pre[class*="language-"] > code[class*="language-"] {
    position: relative;
    z-index: 1;
}
.line-highlight {
    position: absolute;
    left: 0;
    right: 0;
    padding: inherit 0;
    margin-top: 1em;
    background: #f7ebc6;
    box-shadow: inset 5px 0 0 #f7d87c;
    z-index: 0;
    pointer-events: none;
    line-height: inherit;
    white-space: pre;
}
